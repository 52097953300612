@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";


.new-arrivals-products-title {
    color: var(--text-disabled);
    font-family: var(--font-primary);
    font-size: var(--text-xl);
    font-weight: bold;
    line-height: 32px;
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: var(--text-2xl);
        line-height: 40px;
    }
}

.new-arrivals-products-description {
    color: var(--text-disabled);
    font-family: var(--font-primary);
    font-size: var(--text-sm);
    font-weight: normal;
    line-height: var(--text-base);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 24px;
    }
}

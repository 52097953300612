@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./newArrivalsProducts__typography";

.new-arrivals-products {
    &.margins-enabled {
        margin-top: 38px;

        @include media-breakpoint-up(md) {
            margin-top: 64px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 42px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 62px;
        }
    }
}

.new-arrivals-products-title {
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
    }
}

.new-arrivals-products-description {
    margin-bottom: 0;
}

.new-arrivals-products-tiles-wrapper {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
    }

    .experience-region {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    /* stylelint-disable-next-line */
    .experience-assets-productTile {
        width: calc(50% - 10px);

        @include media-breakpoint-up(sm) {
            width: calc(50% - 10px);
        }

        @include media-breakpoint-up(lg) {
            width: calc(25% - 15px);
        }
    }
}
